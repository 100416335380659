import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/xpide.module.scss"

class xpideDisclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.xpide}>
        <div className={Styles.xpide}>
        <p>
        Diese kostenlose Erweiterung wird die Sucheinstellungen dieses Browsers aktualisieren. Wenn Sie fortfahren, erkennen Sie die <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Nutzungsbedingungen</a> und <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>die Datenschutzrichtlinie</a>, an und erhalten möglicherweise zusätzliche optionale Angebote.
          </p>
          </div>
        </div>
    )
  }
}
export default xpideDisclosure
